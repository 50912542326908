import React from "react"
import {default as Link} from "../components/link"

import Layout from "../components/layouts/default"
import SEO from "../components/global/seo"
import {default as Header} from "../components/header"
import Section from "../components/section"
import Column from "../components/column"

import kolossal from "../images/kolossal.svg"

export default () => (
    <Layout className="wrapper--dark">
        <SEO title="Impressum" description="Informationen zum Betreiber dieser Website" />
        <article>
            <Header title="Impressum" classes={['section dark']}>
                <p>Informationen zum Betreiber dieser Website</p>
            </Header>
            <Section classes={['section--masked section--narrow']}>
                <Column>
                    <h2 className="h3">Angaben Gemäss § 5 TMG</h2>
                    <p><strong>eHealth Experts GmbH</strong><br />
                    Emil-Figge-Straße 85<br />
                    44227 Dortmund</p>

                    <h2 className="h3">Geschäftsführer</h2>
                    <p>Frédéric Naujokat</p>

                    <h2 className="h3">Verantwortlich für den Inhalt</h2>
                    <p>eHealth Experts GmbH</p>

                    <h2 className="h3">Kontakt</h2>
                    <p><abbr title="E-Mail">e_</abbr> info (at) ehealthexperts.de<br />
                    <abbr title="Telefon">t_</abbr> +49 231 97 61 95 01<br />
                    <abbr title="Fax">f_</abbr> +49 231 97 61 95 00</p>

                    <h2 className="h3">Registereintrag</h2>
                    <p>Eintragung im Handelsregister.</p>
                    
                    <p>Registergericht: Amtsgericht Dortmund<br />
                    Registernummer: HRB 30690<br />
                    Umsatzsteuer-ID: DE276802349</p>

                    <h2 className="h3">Design &amp; Realisation</h2>
                    <p><Link to="https://kolossal.io" external={true} className="no-external">
                        <img src={kolossal} alt="kolossal Logo" title="kolossal Dortmund" />
                    </Link></p>
                </Column>
            </Section>
        </article>
    </Layout>
)
