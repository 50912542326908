import React from "react"
import PropTypes from "prop-types"
import Column from "./column"
import TextBlock from "./text-block"

function Header({ title, image, classes, children }) {
    
    classes.unshift('header')
    
    return (
        <header className={classes.join(' ')}>
            <div className="header__container container">
                <Column classes={['header__column']}>
                    {image &&
                        <div className="header__image">
                            {image}
                        </div>
                    }
                    <TextBlock title={title} level={1} classes={['header__content']}>
                        {children}
                    </TextBlock>
                </Column>
            </div>
        </header>
    )
}

Header.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.element,
    classes: PropTypes.array
}

Header.defaultProps = {
    classes: []
}

export default Header
