import React from "react"
import PropTypes from "prop-types"

const Section = ({ classes, id, children }) => {
    
    classes.unshift('section')
    
    return (
        <section className={classes.join(' ')} id={id}>
            <div className="section__container container">
                {children}
            </div>
        </section>
    )
}

Section.propTypes = {
    classes: PropTypes.array,
}

Section.defaultProps = {
    classes: []
}

export default Section
